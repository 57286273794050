/* KFC colors */
$Brand-Black: #202124;
$white: #fff;
$black: #000;
$Light-Gray: #e3e3e3;
$Dark-Gray: #494949;
$Brand-Red: #e4002b;
$Mashed-Potato-Gray: #f8f7f5;
$Gray-Medium-inputbox: #777474;
$Inactive-Gray: #dbdbdb;
$Red-Orange: #ff2e00;
$Gray-light: #979797;
$Red-Orange5: #fff4f2;
$Grey-Medium: #595959;
$Alert-Yellow: #fff6e3;
$Gray: #777474;

// Milage Loyalty Color

$regular: #e4002b;
$bronze: #a66826;
$silver: #c7ced2;
$gold: #f6bd41;
$platinum: #a1d6ed;


/* other colors */
$product-Background: #e5e5e5;
$red: #dd2c00 !important;
$bgColor: #efede7;
$btn-disabled: #e5e4e3;
$gold: #c4921b;
$button-border-yellow: #ffbd15;
$gold-hover: #e6a810;
$dark-gold: #bd8b13;
$dark-gold-hover: #e9aa14;
$blue: #005f96;
$focus: #2e4abd;
$blue-done: #0069f7;
$light-grey: #f2f2f2;
$dark-grey: #4e4c4a;
$grey-selected: #ebebed;
$dark-grey-hover: #7a7673;
$grey: #a09e9d;
$navigation-grey: #545454;
$desert-storm: #e5e4e3;
$input-background: #f2f1f0;
$input-background-active: $desert-storm;
$floating-label: #5f5d5c;
$success: #ddf3dd;
$info: #e3f1f9;
$warning: #ffeac0;
$error: #fadfd9;
$focus-color: #00767d;
$tab-bg: #4e4c4a;
$child-tab-bg: #5f5d5c;
$sub-child-tab-bg: #71706e;
$tab-hover: #989797;
$grey-back: #fcfcfc;
$mild-grey: #dcdbdb;
$border-color: #cecece;
$blue-background: #e8f1f4;
$light-grey-border: #a09e9d;
$light-orange: #fff8eb;
$light-gray-scroll: #f1f1f1;
$dark-gray-scroll: #c1c1c1;
$notificayion-sky: #dcecf6;
$disable-grey: #a7a5a4;
$step-completed-color: #009609;
$light-black-color: #202014;
$dark-gray-color: #220022;
$light-gray-border: #e1e1e1;
$gray-background: #20212424;
$gray-lightbg: #626365;
$gray-lighter: #d2d3d3;
$light-red: #fee;
$light-green: #e6f3e6;
$red-lighter: #fff6e3;
